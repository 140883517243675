@import "~antd/dist/antd.css";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  background-color: #f1f1f1;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.header {
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  z-index: 1;
  width: 100%;
}
.ant-layout-footer {
  
  z-index: 1;
  width: 100%;
  padding: 10px;
  background: #000000;
  color: #ffffff;
  text-align: center;
}
.ant-breadcrumb {
  margin: 5px !important;
}

.ant-table-container table > thead > tr:first-child th {
  background: #34324b;
  color: #ffffff;
}
.ant-table-container table > thead > tr:first-child th:hover {
  background: #34324b;
  color: #ffffff;
}
.ant-layout {
  background-color: #f7f7f7;
}
.brand {
  font-size: 20px;
  margin: 10px 10px 0px;
  margin-top: 10px;
  flex: 1;
  font-weight: 400;
}
.ant-btn {
  border-radius: 5px;
}
.ant-btn-primary {
  color: #fff;
  border-color: #830051;
  background: #830051;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #830051;
  background: #830051;
}

.ant-empty {
  margin: 16px auto;
}

.loading {
  margin: 16px auto;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*! Firefox */
html {
  scrollbar-color: #fff #a3a2a2;
}

/*! Other Browser */
html {
  --scrollbarBG: #fff;
  --thumbBG: #a3a2a2;
}
::-webkit-scrollbar {
  width: 11px;
}
* {
  scrollbar-width: thick;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
::-webkit-input-placeholder {
  /* Edge */
  font-size: 16px;
  color: #00000080 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #00000080 !important;
  font-size: 16px;
}

::placeholder {
  font-size: 16px;
  color: #00000080 !important;
}

.sunburst-legend-title {
  padding: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
}
.sunburst-legend-title:hover {
  background-color: #f1f1f1;
}

.sunburst-legend-list {
  padding: 5px 5px 0px 30px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
}
.sunburst-legend-list:hover {
  background-color: #f1f1f1;
}
.sunburst-legend-total {
  margin-left: 10px;
  font-family: "HelveticaNeue-Medium" !important;
  width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.sunburst-legend-total-label {
  padding-left: 5px;
  font-weight: bold;
  font-family: "HelveticaNeue-Medium";
}
.sunburst-legend-total-label1 {
  padding-left: 5px;
  width: 130px;
  font-weight: bold;
  display: flex;
  text-align: right;
  font-family: "HelveticaNeue-Medium";
}
.sunburst-legend-total-percent {
  padding-left: 5px;
  width: 50px;
  font-weight: normal;
  text-align: right;
  font-family: "HelveticaNeue-Light";
}
.sunburst-legend-total-count {
  flex: 1;
  display: inline-block;
  text-align: right;
  font-family: "HelveticaNeue-Medium";
}
.sunburst-legend-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.wheel-legend-title {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 5px 10px;
  height: auto;
}

.wheel-legend-title-1 {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 5px 10px;
  font-size: 14px;
  height: auto;
}

  
.wheel-progress {
  width: 100px !important;
  background-color: #eeeeee;
  margin-right: 10px;
  margin-left: 5px;
}

.wheel-progress-bar {
  width: 5%;
  height: 5px;
  background-color: green;
}


input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none !important;
}

.ant-empty {
  margin: 40px auto;
}