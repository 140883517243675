body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  --antd-wave-shadow-color: #830051 !important; 
  --scroll-bar: 0;
}

::selection {
  color: #fff;
  background: #830051 !important;
}
.ant-layout-header{
  padding: 0px 5px !important;
}
.layout .ant-layout-content{
  padding: 80px 20px 20px;
  min-height: calc(100vh - 70px);
}
.layout .ant-back-top-content:hover {
  background-color: #760041;
}